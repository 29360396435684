<template>
	<div class="fixed">
		<img :src="require('@/assets/core/logo.png')" alt="Chiesi México" class="w-48 sm:w-56">
	</div>
	<div class="min-h-screen bg-white flex">
		<div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
			<div class="mx-auto w-full max-w-sm lg:w-96">
				<div>
					<!-- <img class="h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" /> -->
					<h2 class="mt-16 sm:mt-6 text-3xl font-extrabold text-accent">
						Registro
					</h2>
					<p class="mt-2 text-sm text-gray-600">
						Obtén tu cuenta para participar en nuestra dinámica.
					</p>
				</div>

				<div class="mt-6">
					<form @submit.prevent="submitForm" class="mt-6">
						<div class="space-y-6">
							<div>
								<label for="email" class="block text-sm font-medium text-gray-700">
									Correo electrónico
								</label>
								<div class="mt-1">
									<input v-model="state.model.email" type="email" :disabled="state.sending" autocomplete="email" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-accent focus:border-accent sm:text-sm" />
									<ErrorMessages 
										:v="v$.model.email" 
										type="email"
										v-if="v$.model.email.$error"/>
								</div>
							</div>

							<div class="space-y-1">
								<label for="password" class="block text-sm font-medium text-gray-700">
									Genera una contraseña
								</label>
								<div class="mt-1">
									<input v-model="state.model.pwd" type="password" :disabled="state.sending" autocomplete="current-password" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-accent focus:border-accent sm:text-sm" />
									<ErrorMessages 
										:v="v$.model.pwd" 
										type="pwd"
										v-if="v$.model.pwd.$error"/>
								</div>
							</div>

							<div class="space-y-1">
								<label for="password" class="block text-sm font-medium text-gray-700">
									Confirma la contraseña
								</label>
								<div class="mt-1">
									<input v-model="state.confirmPwd" type="password" :disabled="state.sending" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-accent focus:border-accent sm:text-sm" />
									<ErrorMessages 
										:v="v$.confirmPwd" 
										type="pwdConfirm"
										v-if="v$.confirmPwd.$error"/>
								</div>
							</div>

							<div>
								<div class="flex items-center">
									<input v-model="state.acceptConditions" :disabled="state.sending" type="checkbox" class="h-4 w-4 text-primary focus:ring-accent border-gray-300 rounded" />
									<label for="remember_me" class="ml-2 block text-sm text-gray-900">
										Acepto los 
										<router-link to="/terminos-y-condiciones" class="font-medium text-primary hover:text-accent" v-if="!state.sending">
											Términos y condiciones
										</router-link>
										<span v-if="state.sending">Términos y condiciones</span>
									</label>
								</div>
								<ErrorMessages 
									:v="v$.acceptConditions" 
									type="acceptConditions"
									v-if="v$.acceptConditions.$error"
									class="ml-5"/>
							</div>

							<div>
								<button type="submit" v-if="!state.sending" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent">
									Registrar
								</button>

								<div v-if="state.sending" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-400 bg-gray-200">
									Espera un momento...
								</div>
							</div>
						</div>
					</form>

					<router-link to="/signin" class="flex items-center text-sm text-primary justify-center mt-8" v-if="!state.sending">
						Regresar a inicio
					</router-link>

					<FooterPublic :class="state.sending ? 'mt-12' : 'mt-8'" />
				</div>
			</div>
		</div>
		<div class="hidden lg:block relative w-0 flex-1">
			<img class="absolute inset-0 h-full w-full object-cover" :src="require('@/assets/core/general.png')" alt="" />
		</div>
	</div>
</template>

<script>
import useValidate from '@vuelidate/core'
import { required, email, minLength, sameAs, helpers } from '@vuelidate/validators'
import { reactive, computed } from 'vue'
import ErrorMessages from '@/components/core/ErrorMessages'
import Sign from '@/firebase/sign'
import FooterPublic from '@/components/core/FooterPublic'
import FireErrors from '@/services/fireErrors'

export default {
	components: {
		ErrorMessages,
		FooterPublic
	},
	setup() {
		const state = reactive({
			model: {
				email: '',
				pwd: ''
			},
			confirmPwd: '',
			acceptConditions: false,
			sending: false
		});

		const rules = computed(() => {
			return {
				model: {
					email: { 
						required, 
						email
					},
					pwd: { 
						required, 
						minLength: minLength(6) 
					},
				},
				confirmPwd: { 
					required, 
					sameAs: sameAs(state.model.pwd) 
				},
				acceptConditions: { 
					required, 
					sameAs: sameAs(true) 
				}
			}
		});

		const v$ = useValidate(rules, state);

		function submitForm() {
			v$.value.$validate()

			if (!v$.value.$error) {
				state.sending = true;

				Sign.signup(state.model)
					.then(response => {
						if (response != true) {
							state.sending = false;
                            window.scrollTo(0, 0);
    						store.dispatch('setNotification', {type: false, content: FireErrors.get(response)});
						}
					})
			}
		}

		return {
			state,
			v$,
			submitForm
		}
	}
}
</script>




